<template>
  <div class="reportDetail">
    <van-tabs scrollspy sticky swipeable swipe-threshold="4">
      <h1>康复测评报告</h1>
      <p class="int">欢迎参加“小诺肌骨”膝关节健康测评活动，国家队专业康复师，将为您提供科学健康测评与运动指导建议， 给您的健康生活保驾护航！</p>
      <!-- 每个van-tab相当于一级容器 -->
      <van-tab v-for="(item,i) in reportData" :key="i" :title="item.title">
        <h2>{{item.title}}</h2>
        <!-- 基本信息与其他布局不一致，单独写了一份 -->
        <div class="bgBox" v-if="item.title == '基本信息'">
          <p>姓名：{{item.userData.name}}</p>

          <p>性别：{{item.userData.sex == 0 ? '女' : '男'}}</p>
          <p>年龄：{{item.userData.age}}</p>
          <p>身高：{{item.userData.height}}cm</p>
          <p>体重：{{item.userData.weight}}kg</p>
          <p>医学诊断：{{item.userData.medicalDiagnosis}}</p>
        </div>
        <!-- 除去基本信息模块，其他二级的通用DIV容器 -->
        <p class="firstDes" v-if="item.value">{{item.value}}</p>
        <div class="secondBox" v-if="item.chilren">
          <div class="secondLevel" v-for="(sItem,sIndex) in item.chilren" :key="sIndex">
            <h3>{{sItem.title}}</h3>
            <p class="secondDes" v-if="sItem.value">{{sItem.value}}</p>
            <div class="thirdBox" v-if="sItem.chilren">
              <div class="thirdLevel" v-for="(tItem,tIndex) in sItem.chilren" :key="tIndex" :class="tItem.value || tItem.type == 2 ? '' : 'padding0'">
                <!-- :class="tItem.value ? '' : 'padding0'"-->
                <h4 v-if="tItem.value || tItem.type == 2">{{tItem.title}}</h4><!-- v-if="tItem.value"-->
                <div class="fourthImg" v-if="tItem.imgUrl">
                  <img v-for="(fImg,imgIndex) in tItem.imgUrl" :key="imgIndex" :src="'https://cloudcdn.noitom.com.cn/'+fImg">
                </div>
                <div class="fourthImg" v-if="tItem.videoUrl">
                  <video controls muted webkit-playsinline="true" playsinline="true" x5-video-player-type="h5-page" x5-video-orientation="landscape|portrait" v-for="(fImg,imgIndex) in tItem.videoUrl" :key="imgIndex">
                    <source :src="'https://cloudcdn.noitom.com.cn/'+fImg" type="video/mp4">
                  </video>
                  <!-- <img v-for="(fImg,imgIndex) in tItem.videoUrl" :key="imgIndex" :src="'https://cloudcdn.noitom.com.cn/'+fImg"> -->
                </div>
                <p class="thirdDes" v-if="tItem.value && sItem.type != 3">{{tItem.value}}</p>
                <div v-if="sItem.type == 3 && tItem.value" class="tableBox">
                  <div class="fourthTitle">
                    <span class="titleRadius"></span>
                    <span>左:{{tItem.value ? tItem.value.split(",")[0] : ''}}</span>
                  </div>
                  <div class="fourthTitle">
                    <span class="titleRadius"></span>
                    <span>右:{{tItem.value ? tItem.value.split(",")[1] : ''}}</span>
                  </div>
                </div>

                <div class="fourthBox" v-if="tItem.chilren">
                  <div class="fourthLevel" v-for="(fItem,fIndex) in tItem.chilren" :key="fIndex">
                    <div class="fourthTitle">
                      <span class="titleRadius"></span>
                      <span class="titleDet">{{fItem.title}}</span>
                    </div>
                    <div class="fourthImg" v-if="fItem.imgUrl">
                      <img v-for="(fImg,imgIndex) in fItem.imgUrl" :key="imgIndex" :src="'https://cloudcdn.noitom.com.cn/'+fImg">
                    </div>
                    <div class="fourthImg" v-if="fItem.videoUrl">
                      <video controls muted webkit-playsinline="true" playsinline="true" x5-video-player-type="h5-page" x5-video-orientation="landscape|portrait" v-for="(fImg,imgIndex) in fItem.videoUrl" :key="imgIndex">
                        <source :src="'https://cloudcdn.noitom.com.cn/'+fImg" type="video/mp4">
                      </video>
                      <!-- <img v-for="(fImg,imgIndex) in fItem.videoUrl" :key="imgIndex" :src="'https://cloudcdn.noitom.com.cn/'+fImg"> -->
                    </div>
                    <p class="fourthDes" v-if="fItem.value">{{fItem.value}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { reportDetail } from "@/api/getData"
export default {
  name: "ReportDetail",
  data() {
    return {
      reportData: []
    }
  },
  methods: {
  },
  mounted() {
    reportDetail(this.$route.query.reportId).then(res => {
      this.reportData = res.data
    })
  }
}
</script>

<style lang="scss" scoped>
.reportDetail {
  padding: 0 0.16rem;
  background: #fff;
  :deep(.van-tabs--line .van-tabs__wrap) {
    height: 0.5rem;
    .van-tabs__nav--line.van-tabs__nav--complete {
      padding-right: 0;
      padding-left: 0;
    }
    .van-tab {
      font-size: 0.18rem;
      padding: 0;
      margin-right: 0.32rem;
    }
    .van-tabs__line {
      background: #24bbd3;
      width: 0.6rem;
    }
  }
  h1 {
    font-size: 0.2rem;
    line-height: 0.42rem;
    margin-top: 0.32rem;
    color: #24bbd3;
    border-bottom: 0.02rem solid #24bbd3;
  }

  .int {
    margin-top: 0.32rem;
    line-height: 0.32rem;
    font-size: 0.18rem;
  }

  h2 {
    font-size: 0.18rem;
    color: #0596ad;
    text-align: center;
    margin-top: 0.32rem;
    background: url("../../assets/img/title_bg.png") no-repeat center;
    height: 0.52rem;
    line-height: 0.52rem;
  }

  .bgBox {
    border-radius: 4px;
    background: rgba(219, 254, 255, 0.12);
    box-sizing: border-box;
    border: 1.2px solid rgba(7, 185, 185, 0.2);
    padding: 0.14rem 0 0.32rem;
    margin-top: 0.32rem;

    p {
      margin-top: 0.24rem;
      font-size: 0.18rem;
      line-height: 0.32rem;
      font-weight: 400;
      margin-left: 0.24rem;
      margin-right: 0.24rem;
    }
  }
  .firstDes {
    border-radius: 4px;
    background: rgba(219, 254, 255, 0.12);
    box-sizing: border-box;
    border: 1.2px solid rgba(7, 185, 185, 0.2);
    padding: 0.24rem;
    font-size: 0.16rem;
    line-height: 0.32rem;
    margin: 0.24rem 0 0.4rem;
  }
  .secondLevel {
    border-radius: 4px;
    background: rgba(219, 254, 255, 0.12);
    box-sizing: border-box;
    border: 1.2px solid rgba(7, 185, 185, 0.2);
    padding: 0.14rem 0 0.24rem;
    margin-top: 0.32rem;
    h3 {
      font-size: 0.2rem;
      line-height: 0.3rem;
      color: #24bbd3;
      margin-left: 0.24rem;
      margin-top: 0.16rem;
    }
    .secondDes {
      font-size: 0.16rem;
      line-height: 0.32rem;
      margin-left: 0.24rem;
      margin-top: 0.08rem;
      margin-right: 0.24rem;
    }
    .tableBox {
      display: flex;
      .fourthTitle {
        width: 50%;
      }
    }
    .thirdBox {
      .thirdLevel {
        border-bottom: 2px solid rgba(164, 218, 229, 0.2);
        padding: 0.24rem;
        h4 {
          font-size: 0.18rem;
          line-height: 1;
        }
        .thirdDes {
          font-size: 0.16rem;
          line-height: 0.32rem;
          margin-top: 0.1rem;
        }
        .fourthTitle {
          margin-top: 0.24rem;
          .titleRadius {
            width: 0.12rem;
            height: 0.12rem;
            border-radius: 50%;
            background: #24bbd3;
            display: inline-block;
            margin-right: 0.12rem;
          }
          .titleDet {
            font-size: 0.18rem;
            line-height: 1;
          }
        }
        .fourthBox {
          .fourthLevel {
            .fourthDes {
              font-size: 0.16rem;
              line-height: 0.32rem;
              margin-top: 0.16rem;
            }
          }
        }
      }
      .thirdLevel:last-child {
        border: none;
        padding-bottom: 0;
      }
    }

    .fourthImg {
      img {
        display: block;
        width: 100%;
        margin-top: 0.16rem;
        border-radius: 0.06rem;
      }
      video {
        width: 100%;
        margin-top: 0.16rem;
        border-radius: 0.06rem;
      }
    }
  }

  .padding0 {
    padding: 0 !important;
    border: none !important;
  }
}
</style>